<template>
<div class="container-fluid">
  <div class="row">
    <!-- Lista na members -->
    <div class="col-md-3">
<div class="card">
        <!-- Card header -->
        <div class="card-header">
            <!-- Title -->
            <h5 class="h3 mb-0">Chat</h5>
        </div>
        <!-- Card body -->
        <div class="card-body">
            <!-- List group -->
            <ul class="list-group list-group-flush list my--3">
                <li v-for="client in clients"
                    :key="client.image"
                    class="list-group-item px-0">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <!-- Avatar -->
                            <a href="#" class="avatar" style="background-color:transparent !important;">
                                <img alt="Image placeholder" :src=client.profile_image>
                            </a>
                        </div>
                        <div class="col ml--2">
                            <h4 class="mb-0">
                                <a href="#!">{{client.name}}</a>
                            </h4>
                        </div>
                        <div class="col-auto">
                            <button type="button" @click="selectClient(client)" class="btn btn-sm btn-primary">Chat</button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    </div>
    <!-- Bas chat -->
    <div class="col-md-9" v-if="showChat">
    <div class="card">
        <!-- Card header -->
        <div class="card-header">
            <!-- Title -->
            <h5 class="h3 mb-0">{{ selectedClient.name }}</h5>
        </div>
        <!-- Card body -->
        <div class="card-body" style="height: 550px; overflow-y: scroll;">
            <!-- List group -->
          <ChatMessage v-bind:all-messages=this.messagesWithSelectedClient />
          <div id="zaAnchor"></div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-11">
              <base-input
                prepend-icon="fas fa-comment"
                placeholder="Write your message here"
                v-model="chatMessage.content"
              />
            </div>
            <div class="col-md-1">
              <base-button
                type="button"
                class="btn btn-sm btn-primary mt-2"
                native-type="button"
                @click="sendMessage"
                >Send Message</base-button>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="col-md-8" v-else>
    <div class="card">
      <h3 class="mt-3 mb-3 ml-3">Please select a client to start chatting.</h3>
    </div>
  </div>
  </div>
  </div>
</template>
<script>
  import ChatMessage from "@/views/FirstLook/Chat/ChatMessage.vue";
  import {mapGetters} from 'vuex';
  import Pusher from 'pusher-js';

  export default {
    name: 'chat',
    components: {
      ChatMessage
    },
    mounted() {
      this.$root.$on('updateMessages', (data) => {
        this.messagesWithSelectedClient = data;

        if(this.$route.name == 'All Messages')
        {
          this.messagesWithSelectedClient.map(async message => {
            if(message.seen == 0)
            {
              message.id = message.id.toString();
              message.seen = 1;
              message.type = "messages";

              await this.$store.dispatch('messages/update', message);
            }
          });

          setTimeout(function() {
            let anchor = document.getElementById("zaAnchor");
            anchor.scrollIntoView();
          }, 500);
        }
      });
    },
    computed: {
      ...mapGetters([
        'messages'
      ])
    },
    props: {
      showSearch: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        messages: [],
        selectedClient: {},
        messagesWithSelectedClient: [],
        clients: [],
        channel: {},
        chatMessage: {
          type: 'messages',
          from_user: "1",
          to_user: '',
          content: '',
          seen: 0
        },
        notification: {
          type: 'notifications',
          from_user: "1",
          to_user: '',
          message: ''
        },
        showChat: false,
        membersSearch: ''
      }
    },
    created() {
      this.getClients();
      this.$store.dispatch('messages/GET_MESSAGES', this.selectedClient.id);

      // this.messages = this.$store.getters['messages/messages']; 
      // this.messagesWithSelectedClient = this.$store.getters['messages/messages'];
    },
    methods: {
      async handleMessages(data)
      {
        this.$store.commit('messages/ADD_MESSAGE', data.message);
        this.$store.dispatch('messages/GET_MESSAGES', this.selectedClient.id);

        this.messages = this.$store.getters['messages/messages'];
        this.messagesWithSelectedClient = this.$store.getters['messages/messages'];
        if(this.$route.name == "All Messages")
        {
          let messagesPromise = await Promise.all(this.messagesWithSelectedClient.map(async message => {
            if(message.seen == 0)
            {
              message.id = message.id.toString();
              message.seen = 1;
              message.type = "messages";

              await this.$store.dispatch('messages/update', message);
            }
          })).then(() => {
            this.$root.$emit('refreshNotifications');
          }).then(() => {
            console.log('3');
            let anchor = document.getElementById("zaAnchor");
            anchor.scrollIntoView();
          });
        }
      },
      async getClients() {
        let params = {};
        await this.$store.dispatch("users/list", params);
        this.clients = this.$store.getters["users/list"];

        this.clients = this.clients.filter((user) => {
          return user.name !== "Admin";
        });

        this.clients.map((client) => {
          client.profile_image = "https://" + client.profile_image;
        });
      },
      async selectClient(client) {
        this.selectedClient = client;
        this.showChat = true;

        await this.$store.dispatch('messages/GET_MESSAGES', this.selectedClient.id);
        this.messagesWithSelectedClient = this.$store.getters['messages/messages'];

        let messagesPromise = await Promise.all(this.messagesWithSelectedClient.map(async message => {
          if(message.seen == 0)
          {
            message.id = message.id.toString();
            message.seen = 1;
            message.type = "messages";

            await this.$store.dispatch('messages/update', message);
          }
        })).then(() => {
          this.$root.$emit('updateNotifications', this.$store.state.profile.me.id);
        });

        // Za da refresh notifications dropdown vo navbar
        setTimeout(function() {
          let anchor = document.getElementById("zaAnchor");
          anchor.scrollIntoView();
        }, 500);
      },
      async sendMessage() {
        this.chatMessage.to_user = this.selectedClient.id;

        await this.$store.dispatch('messages/ADD_MESSAGE', this.chatMessage)
          .then(response => {
            this.chatMessage.content = "";
          }).catch(err => {
            this.$notify({
              type: "danger",
              message: "Error sending a message.",
            });
            console.log(err);
            console.log(err.response);
          });

          let anchor = document.getElementById("zaAnchor");
          anchor.scrollIntoView();
      }
    }
  }
</script>
