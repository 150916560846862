import service from "@/store/services/appointments-service";

const state = {
  list: {},
  appointment: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, appointment) => {
    state.appointment = appointment;
  }
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list }) => {
      commit("SET_LIST", list);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then(appointment => {
      commit("SET_RESOURCE", appointment);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then(appointment => {
      commit("SET_RESOURCE", appointment);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then(appointment => {
      console.log('module');
      console.log(params);
      commit("SET_RESOURCE", appointment);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },

  sendNewAppointmentNotification({ commit, dispatch }, params) {
    return service.sendNewAppointmentNotification(params);
  },

  sendAppointmentReminder({ commit, dispatch }, params) {
    return service.sendAppointmentReminder(params);
  }
};

const getters = {
  list: state => state.list,
  appointment: state => state.appointment,
  listTotal: state => state.list.total,
  dropdown: state => {
    return state.list.map(folder => ({
      id: folder.id,
      name: folder.name
    }));
  }
};

const appointments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default appointments;
