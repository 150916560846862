<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <!-- Search form -->
    <form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </form>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>

      
     
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        class="nav-item"
        tag="li"
        :title=this.unreadMessagesCount
        :title-classes=this.titleClass
        title-tag="a"
        icon="ni ni-bell-55"
        menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
      >
        <!-- Dropdown header -->
        <div class="px-3 py-3">
          <h6 class="text-sm text-muted m-0">
            You have <strong class="text-primary">{{ this.unreadMessagesCount }}</strong> unread messages.
          </h6>
        </div>
        <!-- List group -->
        <div class="list-group list-group-flush">
          <a href="#!" class="list-group-item list-group-item-action">
            <div v-for="message in unreadMessagesFilter" :key="message.id" class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <img
                  alt="Image placeholder"
                  :src="`https://${message.from_user_resource.profile_image}`"
                  class="avatar rounded-circle"
                />
              </div>
              <div class="col ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">From: {{ message.from_user_resource.name }}</h4>
                  </div>
                  <div class="text-right text-muted">
                    <small>{{ message.created_at }}</small>
                  </div>
                </div>
                <p class="text-sm mb-0">
                  {{ (message.content.length > 40) ? message.content.substr(0, 39) + '...' : message.content }}
                </p>
              </div>
            </div>
          </a>
        </div>
        <!-- View all -->
        <a
          class="dropdown-item text-center text-primary font-weight-bold py-3"
          @click="goToInbox()"
          >View all</a
        >
      </base-dropdown>
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
            <span
              class="avatar avatar-sm rounded-circle avatar-image"
              :style="{
                'background-image': `url('${profileImage}')`,
              }"
            >
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{ title }}</span>
            </div>
          </div>
        </a>

        <template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <a href="/examples/user-profile" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </a>
          <div class="dropdown-divider"></div>
          <a @click.prevent="logout()" to="" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from "@/components";

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  async created() {
    this.$store.watch(
      () => this.$store.getters["profile/me"],
      (me) => {
        this.title = me.name;
        this.avatar = "https://" + me.profile_image;
      }
    );
    await this.$store.dispatch("profile/me");

    let pusher = new Pusher(`39dfb59e22eb40732349`, {
      cluster: `eu`,
      encrypted: true
    });
    this.channel = pusher.subscribe('firstlook');

    this.channel.bind('newChatMessage', async (data) => {
      this.handleNewMessage(data);
    });

    // And on initial load, not just on 'newChatMessage'
    this.handleNewMessage(null);
  },
  mounted() {
    this.$root.$on('updateNotifications', (user) => {
      this.unreadMessagesCount = 0;
      this.unreadMessages = [];

      this.$store.dispatch('messages/GET_MESSAGES', user.id);

      let messages = this.$store.getters['messages/messages'];

      messages.filter((message) => {
        return message.to_user == user.id;
      }).map(async (message) => {
        if(message.seen == 0)
        {
          this.unreadMessagesCount++;

          if(this.unreadMessages.length <= 4)
          {
            this.unreadMessages.push(message);
          }
        }
      });

      if(this.unreadMessagesCount > 0)
        this.titleClass = "nav-link text-danger";
      else
        this.titleClass = "nav-link ";
    });
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    profileImage() {
      return this.avatar ? this.avatar : "/img/placeholder.jpg";
    },
    unreadMessagesFilter() {
      return this.unreadMessages.slice(0, 5);
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      title: "Profile",
      avatar: null,
      channel: {},
      unreadMessages: [],
      unreadMessagesCount: 0,
      titleClass: "nav-link "
    };
  },
  methods: {
    async handleNewMessage(data) {
      if (data == null) return;

      await this.$store.dispatch('messages/GET_MESSAGES', data.message.to_user);

      let messages = this.$store.getters['messages/messages'];
      this.$root.$emit('updateMessages', messages);
      if(this.$route.name != 'All Messages')
      {
        this.$root.$emit('updateNotifications', this.$store.state.profile.me);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    goToInbox() {
      this.$router.push({
        name: 'All Messages'
      })
    },
    async logout() {
      try {
        this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },
};
</script>
