import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/users`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .get(`${url}/users/${id}?include=roles`, options)
    .then(response => {
      let user = jsona.deserialize(response.data);
      delete user.links;
      return user;
    });
}

function add(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: null
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .post(`${url}/users?include=roles`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function insertDocument(document) {
  const payload = jsona.serialize({
    stuff: document
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .post(`${url}/documents`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(message) {
  const payload = jsona.serialize({
    stuff: message,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .patch(`${url}/messages/${message.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.delete(`${url}/users/${id}`, options);
}

function upload(user, image) {
  const payload = new FormData();
  payload.append("attachment", image);

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .post(`${url}/uploads/users/${user.id}/profile-image`, payload, options)
    .then(response => {
      return response.data.url;
    });
}

function uploadDocument(user, document) {
  console.log(document);
  const payload = new FormData();
  payload.append("attachment", document);

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .post(`${url}/uploads/users/${user.id}/documents`, payload, options)
    .then(response => {
      return response.data.url;
    });
}

function welcomeEmail(user) {
  const payload = jsona.serialize({
    stuff: user
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.post(`${url}/email/welcome`, payload, options);
}

function getMessages(to_user) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .get(`${url}/messages?filter[chatUser]=${to_user}`, options)
    .then(response => {
      let messages = jsona.deserialize(response.data);
      return messages;
    });
}

function addMessage(params) {
  const payload = jsona.serialize({
    stuff: params
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .post(`${url}/messages`, payload, options)
    .then(response => {
      let messages = jsona.deserialize(response.data);
      return messages;
    });
}

function sendChatNotification(params) {
  const payload = jsona.serialize({
    stuff: params
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .post(`${url}/notifications`, payload, options)
    .then(response => {
      let messages = jsona.deserialize(response.data);
      return messages;
    });
}

export default {
  list,
  get,
  add,
  insertDocument,
  update,
  destroy,
  upload,
  uploadDocument,
  welcomeEmail,
  getMessages,
  addMessage,
  sendChatNotification
};
