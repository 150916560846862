var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-chart-pie-35 text-primary',
          path: '/dashboard',
        }}}),(_vm.roles[0] == 'admin')?_c('sidebar-item',{attrs:{"link":{
          name: 'Clients',
          icon: 'fa fa-user text-primary',
          path: '/clients/user-management/list-users'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Projects',
          icon: 'ni ni-building text-primary',
          path: '/projects/project-management/list-projects'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Inbox',
          icon: 'ni ni-email-83 text-primary',
          path: '/inbox' 
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }