<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Edit Folder</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Back to folder</base-button
              >
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="handleSubmit">
              <base-input
                label="Folder Name"
                prepend-icon="fas fa-laptop"
                v-model="folder.name"
              />
              <validation-error :errors="apiValidationErrors.name" />
              
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Edit Folder
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option
  },

  data() {
    return {
      available_users: [],
      folder: {
        type: "folders",
        name: null,
      }
    };
  },
  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("folders/get", id);
        this.folder = this.$store.getters["folders/folder"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async handleSubmit() {
      try {
        await this.$store.dispatch("folders/update", this.folder);

        this.$notify({
          type: "success",
          message: "Folder updated successfully.",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    goBack() {
      this.$router.push({ 
        name: "View Folder",
        params: {
            id: this.$route.params.id
        }
      });
    },
  },
};
</script>
