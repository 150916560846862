import service from '@/store/services/messages-service';
import Jsona from 'jsona';

const jsona = new Jsona();

export const state = () => ({
  list: {},
  messages: {},
  notifications: {}
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_MESSAGES: (state, messages) => {
    state.messages = messages;
  },
  GET_MESSAGES(state, messages) {
    state.messages = messages
  },
  ADD_MESSAGE(state, message) {
    state.messages = [...state.messages, message]
  },
  ADD_NOTIFICATION(state, notification) {
    state.notifications = [...state.notifications, notification]
  }
};

export const actions = {
  ADD_MESSAGE({commit, dispatch}, params) {
    return service.addMessage(params, this.$axios);
  },

  GET_MESSAGES({commit}, to_user) {
    return service.getMessages(to_user)
      .then((messages) => {
        commit("SET_MESSAGES", messages);
        commit("SET_LIST", messages);
      })
  },

  list({commit, dispatch}, params = {}) {
    return service.list(params, this.$axios)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params, this.$axios)
      .then((user) => { commit('SET_RESOURCE', user); });
  },

  add({commit, dispatch}, params) {
    return service.add(params, this.$axios)
      .then((user) => { commit('SET_RESOURCE', user); });
  },

  update({commit, dispatch}, params) {
    return service.update(params, this.$axios);
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params, this.$axios);
  },

  upload({commit, dispatch}, {user, image}) {
    return service.upload(user, image, this.$axios)
      .then((url) => {
        commit('SET_URL', url);
      });
  },

  uploadDocument({commit, dispatch}, {user, document}) {
    return service.uploadDocument(user, document, this.$axios);
  },

  insertDocument({commit, dispatch}, document) {
    return service.insertDocument(document, this.$axios);
  },

  welcomeEmail({commit, dispatch}, params) {
    return service.welcomeEmail(params, this.$axios);
  },

  sendChatNotification({commit, dispatch}, params) {
    return service.sendChatNotification(params, this.$axios)
      .then((notification) => {
        commit("ADD_NOTIFICATION", notification);
      });
  }
};

const getters = {
  messages: state => state.messages,
  list: state => state.list
};

const messages = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default messages;
