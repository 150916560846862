<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Create Folder <span v-if="parent_folder.name"> for parent folder {{ parent_folder.name }}</span></h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Abort</base-button
              >
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="handleSubmit">
              

                <div class="form-group">
                <label class="form-control-label"> Folder Image </label>
                <div v-if="image" class="profile-image card-img pb-4">
                  <img :src="`${image}`" class="profile-image argon-image" />
                </div>
                <div v-else class="profile-image">
                  <img src="/img/placeholder.jpg" class="argon-image" />
                </div>
                <div class="image-upload">
                  <base-button
                    v-if="image"
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="removeImage"
                  >
                    <span>
                      <i class="fa fa-times" />
                      Remove
                    </span>
                  </base-button>
                  <base-button type="button" class="btn btn-sm btn-primary">
                    <label v-if="!image" for="imageInput" class="mb-0"
                      >Select image</label
                    >
                    <label v-else for="imageInput" class="mb-0">Change</label>
                    <input
                      id="imageInput"
                      ref="imageInput"
                      accept="image/*"
                      type="file"
                      style="display: none"
                      @input="onSelectFile"
                    />
                  </base-button>
                </div>
              </div>
              <validation-error :errors="apiValidationErrors.attachment" />

              <base-input
                label="Folder Name"
                prepend-icon="fas fa-laptop"
                v-model="folder.name"
              />
              <validation-error :errors="apiValidationErrors.name" />

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Create Folder
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import { omit } from "lodash";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option
  },

  data() {
    return {
        image: "",
        file: null,
        parent_folder: {
          type: "folders",
          name: null
        },
        folder: {
            type: "folders",
            project_id: 0,
            // Parent folder = 0 means it's a top level folder under a project.
            parent_folder: 0,
            folder_image: null,
            folder_date: null,
            name: null,
            is_public: 0
        }
    };
  },
  mounted() {
      if(!this.$route.params.folder_id)
        this.folder.project_id = this.$route.params.project_id;

      this.folder.folder_date = new Date().toISOString().slice(0, -5).replace("T", " ");
      this.folder.folder_image = "placeholder";
  },
  created() {
    if(this.$route.params.folder_id)
      this.getParentFolder(this.$route.params.folder_id);
  },

  methods: {
    async getParentFolder(parentFolderId) {
      try {
        await this.$store.dispatch("folders/get", parentFolderId);
        this.parent_folder = this.$store.getters["folders/folder"];
      } catch (error) {
        console.log(error);
        console.log(error.response);
        this.$notify({
            type: "danger",
            message: `Oops, something went wrong!`,
        });

        this.setApiValidation(error.response.data.errors);
      }
    },
    async handleSubmit() {
      try {
        if(this.$route.params.folder_id)
        {
          this.folder.project_id = 0;
          this.folder.parent_folder = this.parent_folder.id;
        }

        await this.$store.dispatch("folders/add", this.folder);

        this.folder = await this.$store.getters["folders/folder"];

        if (this.file)
        {
          let stateFolderImage = await this.$store.dispatch("folders/upload", {
            folder: this.folder,
            image: this.file,
          });

          this.folder.folder_image = stateFolderImage;

          await this.$store.dispatch("folders/update", this.folder);
        } 
        else 
        {
          await this.$store.dispatch("projects/get", this.folder.project_id);
          let clientId = this.$store.getters["projects/project"].user_id;
          await this.$store.dispatch("users/get", clientId);

          this.folder.folder_image = await this.$store.getters["users/user"].profile_image;
          await this.$store.dispatch("folders/update", this.folder);

          this.file = null;
        }

        this.$notify({
          type: "success",
          message: "Folder added successfully.",
        });
        
        this.goBack();
      } catch (error) {
        console.log(error);
        console.log(error.response);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!"
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    removeImage() {
      this.image = null;
    },

    goBack() {
      if(this.$route.params.folder_id)
        this.$router.push({ name: "View Folder", params: { id: this.$route.params.folder_id } });
      else
        this.$router.push({ name: "View Project", params: { id: this.$route.params.project_id } });
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },
  }
};
</script>

