import store from "../store";

export default async function client({ next, router }) {
  await store.dispatch("profile/me");
  let me = await store.getters["profile/me"];
  if (store.getters.isAuthenticated && me.roles[0].name==='member') {
    store.state.isAdmin = false;
  }
  else
  {
    store.state.isAdmin = true;
  }
  
  return next();
}
