<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-right text-muted">
          Developed By <a class="font-weigth-bold ml-1" target="_blank" rel="noopener" href="https://creativehouse.marketing/">Creative House</a> © <a class="font-weigth-bold ml-1" target="_blank" rel="noopener" href="https://firstlookservice.com">FirstLook</a> 2022. All Rights Reserved
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
