<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-6 order-xl-1">
        <div>
          <user-edit-card :user="user" />
        </div>
      </div>
      <div class="col-xl-6 order-xl-2">
        <user-password-card :user="user" />
        <list-user-documents 
          v-bind:all-documents=this.user.documents />
      </div>
    </div>
  </div>
</template>
<script>
import UserEditCard from "@/views/Examples/UserProfile/UserEditCard.vue";
import UserPasswordCard from "@/views/Examples/UserProfile/UserPasswordCard.vue";
import UserCard from "@/views/Pages/UserProfile/UserCard.vue";
import ListUserDocuments from "@/components/ListUserDocuments.vue";

export default {
  layout: "DashboardLayout",

  components: {
    UserEditCard,
    UserPasswordCard,
    UserCard,
    ListUserDocuments
  },

  data() {
    return {
      user: {
        type: "profile",
        name: null,
        email: null,
        profile_image: null,
      },
    };
  },
  created() {
    this.getProfile();
  },

  methods: {
    async getProfile() {
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
    },
  },
};
</script>
