import axios from "axios";
import store from "@/store";
const url = process.env.VUE_APP_API_BASE_URL;

const actions = {
  async activateAndSetPassword({ commit }, data) {
    console.log(data);
    await axios.post(`${url}/activate`, data);

    // const user = {
    //   data: {
    //     type: "token",
    //     attributes: {
    //       id: data.attributes.id,
    //       password: data.attributes.password
    //     }
    //   }
    // };

    // const requestOptions = {
    //   headers: {
    //     Accept: "application/vnd.api+json",
    //     "Content-Type": "application/vnd.api+json"
    //   }
    // };

    // store.dispatch("activate", { user, requestOptions }, { root: true });
  }
};

const activate = {
  namespaced: true,
  actions
};

export default activate;
