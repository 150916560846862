import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/folders`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/folders/${id}`, options)
    .then(response => {
      let folder = jsona.deserialize(response.data);
      delete folder.links;
      return folder;
    });
}

function getPublicOnly(share_link) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/public/folders/${share_link}`, options)
    .then(response => {
      let folder = response.data;
      delete folder.links;
      return folder;
    });
}

function getPublic(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/public/project/folders/${id}`, options)
    .then(response => {
      let folder = response.data;
      delete folder.links;
      return folder;
    });
}

function add(folder) {
  const payload = jsona.serialize({
    stuff: folder,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/folders`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(folder) {
  const payload = jsona.serialize({
    stuff: folder,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/folders/${folder.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/folders/${id}`, options);
}

function getMedia(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/folders/${id}/media`, options)
    .then(response => {
      let media = jsona.deserialize(response.data);
      return media;
    });
}

function mediaUpload(folder, image) {
  const payload = new FormData();
  payload.append("attachment", image);

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  
  return axios
    .post(`${url}/uploads/folders/${folder.id}/media`, payload, options)
    .then(response => {
      return response.data.url;
    });
}

function mediaInsert(media) {
  const payload = jsona.serialize({
    stuff: media
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  
  return axios.post(`${url}/media`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function upload(folder, image) {
  const payload = new FormData();
  payload.append("attachment", image);

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  
  return axios
    .post(`${url}/uploads/folders/${folder.id}/folder_image`, payload, options)
    .then(response => {
      return response.data.url;
    });
}

function mediaDestroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/media/${id}`, options);
}

function contentChangeEmail(folder) {
  const payload = jsona.serialize({
    stuff: folder
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.post(`${url}/email/contentChange`, payload, options);
}

export default {
  list,
  get,
  getPublic,
  add,
  update,
  getMedia,
  upload,
  mediaUpload,
  getPublicOnly,
  mediaInsert,
  mediaDestroy,
  destroy,
  contentChangeEmail
};
