<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">View Folder: {{ this.folder.name }}</h3>
            </div>
            <div class="col-4 text-right">
             <!-- <base-button
                v-if=isAdmin
                @click="modals.form = true"
                type="button"
                class="btn btn-sm btn-primary"
                >Add Media</base-button
              > -->
              <base-dropdown
                menu-on-right
                class="nav-item"
                tag="li"
                title-tag="a"
                title-classes="nav-link pr-0"
              >
                <a href="#" class="btn btn-sm btn-primary" @click.prevent slot="title-container">
                  Actions
                </a>

                <template>
                  <a @click="editFolder()" class="dropdown-item">
                    <span>Edit</span>
                  </a>
                  <a @click="download()" href="#" class="dropdown-item">
                    <span>Download ZIP</span>
                  </a>
                  <a v-if=isAdmin @click="addFolder()" class="dropdown-item">
                    <span>Create Folder</span>
                  </a>
                  <a @click="copyPublicLink()" v-if="folder.is_public == 'Make Private'" href="#" class="dropdown-item">
                    <span>Copy Link</span>
                  </a>
                  <a v-if=isAdmin @click="makePublic()" class="dropdown-item">
                    <span>{{ folder.is_public }}</span>
                  </a>
                  <a v-if=isAdmin @click="modals.form = true" class="dropdown-item">
                    <span>Add Media</span>
                  </a>
                  <a v-if=isAdmin @click="modals.image = true" class="dropdown-item">
                    <span>Change Cover Image</span>
                  </a>
                </template>
              </base-dropdown>
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Back to list</base-button
              >
            </div>
          </div>
          <div class="card-body row" v-if="this.folder.children && this.folder.children.length != 0">
              <div class="col-md-3" v-for="folder in this.folder.children" :key="folder.id">
                <div class="card">
                    <!-- Card image -->
                    <img @click="viewFolder(folder.id)" style="object-fit: scale-down !important;" class="card-img-top" :src=folder.folder_image alt="Image placeholder">
                    <!-- Card body -->
                    <div class="card-body">
                    <h5 class="h2 card-title mb-0">{{ folder.name }}</h5>
                    <base-button v-if=isAdmin type="button" @click="deleteFolder(folder.id)" class="btn btn-link text-danger px-0">Delete</base-button>
                    </div>
                </div>
              </div>
              </div>
              <hr v-if="this.folder.children && this.folder.children.length != 0" />
              <div class="card-body row">
              <div class="col-md-3" v-for="media in this.folder.media" :key="media.id">
                <div class="card" v-if="media.isImage">
                    <!-- Card image -->
                    <img @click="showImageModal(media)" style="object-fit: scale-down !important; height: 250px !important;" class="card-img-top" :src="media.name" alt="Image">
                    <!-- Card body -->
                    <div class="card-body">
                    <!-- <a type="text" class="btn btn-link px-0">View</a> -->
                    <a v-if=isAdmin @click="deleteMedia(media.id)" type="text" class="btn btn-link text-danger px-0">Delete</a>
                    </div>
                </div>
                <div class="card" v-else>
                <video
                  id="my-video"
                  class="video-js w-auto"
                  controls
                  preload="auto"
                  data-setup="{}"
                  style="object-fit: scale-down !important; height: 250px !important;"
                >
                  <source :src="media.name" type="video/mp4" />
                  <p class="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to a
                    web browser that
                    <a href="https://videojs.com/html5-video-support/" target="_blank"
                      >supports HTML5 video</a
                    >
                  </p>
                </video>
                <div class="card-body">
                    <!-- <a type="text" class="btn btn-link px-0">View</a> -->
                    <a v-if=isAdmin @click="deleteMedia(media.id)" type="text" class="btn btn-link text-danger px-0">Delete</a>
                    </div>
                </div>
              </div>
          </div>

            <modal modalClasses="modal-dialog-img" :show.sync="modals.img"
                   body-classes="p-0" body-style="display: block;">

                  <img :src="selectedImage" style="height: 100%; width: 100%; display: block;"/>

            </modal>
            <modal :show.sync="modals.image"
                   size="sm"
                   body-classes="p-0">
              <card type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                <template slot="header">
                  <div class="text-muted text-center mb-3">
                    <small>Change cover image</small>
                  </div>
                </template>
                <template>
                  <form enctype="multipart/form-data" role="form" @submit.prevent="handleCoverSubmit" class="text-center">
                    <div class="form-group">
                <label class="form-control-label"> </label>
                <div v-if="cover_image" class="profile-image card-img" style="margin-left: 48% !important; transform: translateX(-50%) !important;">
                  <img :src="`${cover_image}`" class="profile-image argon-image" />
                </div>
                <div v-else class="profile-image" style="margin-left: 48% !important; transform: translateX(-50%) !important;">
                  <img src="/img/placeholder.jpg" class="argon-image" />
                </div>
                <div class="image-upload">
                  <base-button
                    v-if="cover_image"
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="removeCoverImage"
                  >
                    <span>
                      <i class="fa fa-times" />
                      Remove
                    </span>
                  </base-button>
                  <base-button type="button" class="btn btn-sm btn-primary">
                    <label v-if="!cover_image" for="imageInput" class="mb-0"
                      >Select image</label
                    >
                    <label v-else for="imageInput" class="mb-0">Change</label>
                    <input
                      id="imageInput"
                      ref="imageInput"
                      accept="image/*"
                      multiple="multiple"
                      type="file"
                      style="display: none"
                      @input="onSelectCoverFile"
                    />
                  </base-button>
                </div>
              </div>
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Upload Image
                </base-button>
                <div v-if="loading">
                      Upload in progress
                      <img src="/img/loading-unscreen.gif" style="height: 100px; width: 100px" />
                    </div>
                    </form>
                </template>
              </card>

            </modal>
          <modal :show.sync="modals.form"
                   size="sm"
                   body-classes="p-0">
              <card type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                <template slot="header">
                  <div class="text-muted text-center mb-3">
                    <small>Upload media to this folder</small>
                  </div>
                </template>
                <template>
                  <form enctype="multipart/form-data" role="form" @submit.prevent="handleSubmit" class="text-center">
                    <div class="form-group">
              <dropzone-file-upload
                v-model="inputs.fileMultiple"
                multiple
                url="http://159.223.159.100/json/api/v1/uploads/folders/67/">
                </dropzone-file-upload>
              </div>
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Upload Media
                </base-button>
                <div v-if="loading">
                      Upload in progress
                      <img src="/img/loading-unscreen.gif" style="height: 100px; width: 100px" />
                    </div>
                    </form>
                </template>
              </card>

            </modal>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Modal } from '@/components';
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload';
import JSZip from "jszip";
import saveAs from "save-as";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
      Modal,
      DropzoneFileUpload
  },

  data() {
    return {
        loading: false,
        isAdmin: false,
        image: "",
        cover_image: "",
        file: null,
        selectedImage: "",
        inputs: {
          fileMultiple: []
        },
        modals: {
            form: false,
            img: false,
            image: false,
        },
        media: {
          type: 'media',
          folder_id: null,
          name: null,
          isImage: false
        },
        folder: {
            name: null,
        },
        formModal: {
          email: ''
        }
    };
  },
  created() {
    this.isAdmin = this.$store.state.isAdmin;
    this.get();
  },
  watch: {
    '$route': function(){
      this.get();
    }
  },

    methods: {
      editFolder()
      {
        this.$router.push({
          name: 'Edit Folder',
          params: {
            id: this.$route.params.id
          }
        });
      },
      viewFolder(id)
      {
        this.$router.push({
          name: 'View Folder',
          params: { id: id }
        });

        this.get();
      },
      async deleteFolder(id) {
      const confirmation = await swal.fire({
        title: `Delete this folder? This will delete all of the associated data with it!`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("folders/destroy", id);
          await this.get();
          this.$notify({
            type: "success",
            message: "Folder deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
      showImageModal(media)
      {
        this.modals.img = true
        this.selectedImage = media.name;
      },
      async copyPublicLink()
      {
        const publicLink = "https://portal.firstlookservice.com/publicFolder/" + this.folder.share_link;

        // Copy to clipboard thingy: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
        const el = document.createElement('textarea');
        el.value = publicLink;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        let title = "Folder public link copied to your clipboard, or you can copy it here: " + publicLink;
        await swal.fire({
          title: `Link copied successfully!`,
          text: title,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        });
      },
      async makePublic()
      {
        try {
          let result = '';
          let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let charactersLength = characters.length;
          for ( let i = 0; i < 36; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }

          const id = this.$route.params.id;
          await this.$store.dispatch("folders/get", id);
          this.folder = this.$store.getters["folders/folder"];
          this.folder.share_link = result;
          for (const mediaObj of this.folder.media)
          {
            let fileExt = mediaObj.name.split('.').pop();
            if(fileExt == "mp4")
              mediaObj.isImage = false;
            else
              mediaObj.isImage = true;
          }

          if(this.folder.folder_image != 'placeholder')
            this.cover_image = "https://" + this.folder.folder_image;

          this.folder.children.map((folder) => {
            if(folder.folder_image == 'placeholder')
              folder.folder_image = '/img/placeholder-folder.png';
            else
              folder.folder_image = "https://" + folder.folder_image;
          });

          if(this.folder.share_link == null)
            delete this.folder.share_link;

          this.folder.is_public = !this.folder.is_public;

          const type = this.folder.is_public == 0 ? "private" : "public";

          await this.$store.dispatch("folders/update", this.folder);

          this.folder.is_public = (this.folder.is_public == 0) ? "Make Public" : "Make Private";
          
          this.$notify({
            type: "success",
            message: "Folder made " + type + " successfully.",
          });
        } catch (error) {
          console.log(error);
          console.log(error.response);
        }
      },
      async handleSubmit()
        {
            try {
                if (this.inputs.fileMultiple) {
                  this.loading = true;
                  for (const f of this.inputs.fileMultiple) {
                    let url = await this.$store.dispatch("folders/mediaUpload", {
                        folder: this.folder,
                        image: f,
                    });

                    this.media.name = "https://" + url;
                    this.media.folder_id = this.$route.params.id;

                    await this.$store.dispatch("folders/mediaInsert", this.media);
                  }
                }

                await this.$store.dispatch("folders/contentChangeEmail", this.folder);

                this.modals.form = false;
                this.image = "";
                this.file = null;
                this.loading = false;
                this.get();
                
                this.$notify({
                    type: "success",
                    message: "Media uploaded successfully.",
                });
            } catch(error) {
                console.log(error);
                console.log(error.response);
                this.$notify({
                    type: "danger",
                    message: "Oops, something went wrong!",
                });
            }
        },
      async handleCoverSubmit()
      {
        try {
          if (this.file) {
            this.loading = true;

            await this.$store.dispatch("folders/upload", {
              folder: this.folder,
              image: this.file[0]
            });

            this.folder.folder_image = await this.$store.getters["folders/folder_image"];

            let tempIsPublic = this.folder.is_public;
            if(tempIsPublic == 'Make Public')
              this.folder.is_public = 0;
            else
              this.folder.is_public = 1;

            await this.$store.dispatch("folders/update", this.folder);

            this.folder.is_public = tempIsPublic;
          }

          this.modals.image = false;
          this.file = null;
          this.loading = false;
          
          this.$notify({
              type: "success",
              message: "Folder cover changed successfully.",
          });
        } catch(error) {
          console.log(error);
          console.log(error.response);
          this.$notify({
            type: "danger", 
            message: "Oops, something went wrong!",
          });
        }
      },

        async get() {
            try {
                const id = this.$route.params.id;
                await this.$store.dispatch("folders/get", id);
                this.folder = this.$store.getters["folders/folder"];

                for (const mediaObj of this.folder.media)
                {
                  let fileExt = mediaObj.name.split('.').pop();
                  if(fileExt == "mp4")
                    mediaObj.isImage = false;
                  else
                    mediaObj.isImage = true;
                }

                this.folder.is_public = (this.folder.is_public == 0) ? "Make Public" : "Make Private";
                if(this.folder.folder_image != 'placeholder')
                  this.cover_image = "https://" + this.folder.folder_image;

                this.folder.children.map((folder) => {
                  if(folder.folder_image == 'placeholder')
                    folder.folder_image = '/img/placeholder-folder.png';
                  else
                    folder.folder_image = "https://" + folder.folder_image;
                });
            } catch (error) {
                console.log(error);
                console.log(error.response);
                this.$notify({
                    type: "danger",
                    message: `Oops, something went wrong!`,
                });

                this.setApiValidation(error.response.data.errors);
            }
        },

        async onSelectFile(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.createImage(files);
        },

          async onSelectCoverFile(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.createCoverImage(files);
        },

        removeImage() {
            this.image = null;
        },

        removeCoverImage() {
            this.cover_image = null;
        },

        createCoverImage(files) {
          this.file = files;
          
          let reader = new FileReader();
          reader.onload = (e) => {
              this.cover_image = e.target.result;
          };

          reader.readAsDataURL(files[0]);
        },

        createImage(files) {
          this.file = files;
          
          let reader = new FileReader();
          reader.onload = (e) => {
              this.image = e.target.result;
          };

          reader.readAsDataURL(files[0]);
        },
        
        upload()
        {
            this.$router.push({
                name: "Add Folder",
                params: {
                    project_id: this.$route.params.id
                }
            });
        },

        async deleteMedia(id)
        {
          const confirmation = await swal.fire({
            title: `Delete this media?`,
            type: "question",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });

          try {
            if (confirmation.value === true) {
              await this.$store.dispatch("folders/mediaDestroy", id);
              this.$notify({
                type: "success",
                message: "Media deleted successfully.",
              });
            }

            this.get();
          } catch (error) {
            this.$notify({
              type: "danger",
              message: "Oops, something went wrong!",
            });
          }
        },

        goBack() {
            this.$router.push({
                name: "List Projects"
            });
        },
        addFolder()
        {
            this.$router.push({
              name: "Add Folder",
              params: {
                project_id: "0",
                folder_id: this.$route.params.id
              }
            });
        },
        async download()
        {
          const ZIP = new JSZip();

          let folder_path = "/img/folders/" + this.folder.id;
          let zipFolder = ZIP.folder("media");

          this.folder.media.map((mediaObj) => {
            fetch(mediaObj.name).then((res) => {
              let response = res.clone();
              const fileName = mediaObj.name.substring(mediaObj.name.lastIndexOf('/') + 1);

              response.blob().then((blobObj) => {
                zipFolder.file(fileName, blobObj, {base64: true});
              });
            });
          });

          setTimeout(() => {
            ZIP.generateAsync({
              type: "blob"
            }).then(function(content) {
              saveAs(content, "archive_firstlook.zip");
            });
          }, "500");
        }
  },
};
</script>
