<template>
<div>
<div v-if=!allMessages.length>
    <div>
        No messages yet. Send a message to start a conversation!
    </div>
    </div>
<div else>
  <div v-for="message in allMessages" :key="message.id">
        <div class="media w-50 ml-auto mb-3" v-if="message.from_user == 1">
          <div class="media-body">
            <div class="bg-primary rounded py-2 px-3 mb-2">
              <p class="text-small mb-0 text-white" style="font-weight: 500 !important">{{ message.content }}</p>
            </div>
            <p class="small text-muted">{{ message.created_at.substring(0, 10) }}</p>
          </div>
        </div>
        <div class="media w-50 mb-3" v-else>
        <div class="media-body ml-3">
        <div class="bg-light rounded py-2 px-3 mb-2" style="background-color: #aaabab !important">
            <p class="text-small mb-0 text-white" style="font-weight: 500 !important">{{ message.content }}</p>
        </div>
        <p class="small text-muted">{{ message.created_at.substring(0, 10) }}</p>
        </div>
    </div>
</div>
</div>
</div>
</template>
<script>
  export default {
    name: 'ChatMessageClient',
    props: ['allMessages'],
    
    data() {
      return {
        type: '', // Sender/Receiver, for which color/side to add
        datetime: '',
      }
    }
  }
</script>
