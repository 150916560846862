<template>
    <div>
      <card
        class="no-border-card"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Documents</h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                  @click="modals.form = true"
                >
                  Upload Document
                </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >

            <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Search..."
                clearable
              />
            </div>
          </div>
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="documents"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              label="Name"
              min-width="60px"
              prop="name"
              sortable="custom"
            />
            <el-table-column
              label="Added"
              min-width="60px"
              prop="created_at"
              sortable="custom"
            />
            <el-table-column min-width="50px" align="center">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip content="Download" placement="top">
                  <a
                    type="text"
                    @click="download(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-download"></i>
                  </a>
                </el-tooltip>

                <el-tooltip content="Delete" placement="top">
                  <a
                    type="text"
                    @click="deleteDocument(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ this.total ? from + 1 : 0 }} to {{ to }} of
              {{ total }} documents

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
      <modal :show.sync="modals.form"
                   size="sm"
                   body-classes="p-0">
              <card type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                <template slot="header">
                  <div class="text-muted text-center mb-3">
                    <small>Upload client document</small>
                  </div>
                </template>
                <template>
                  <form role="form" @submit.prevent="uploadDocument" class="text-center">
                    <div class="form-group">
                <label class="form-control-label"> </label>
                <div v-if="document" class="profile-image card-img" style="margin-left: 50% !important; transform: translateX(-50%) !important;">
                  <span>Selected document: {{ document }}</span>
                </div>
                <div v-else class="profile-image" style="margin-left: 50% !important; transform: translateX(-50%) !important;">
                  <span>No document selected</span>
                </div>
                <div class="image-upload">
                  <base-button
                    v-if="document"
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="removeDocument"
                  >
                    <span>
                      <i class="fa fa-times" />
                      Remove
                    </span>
                  </base-button>
                  <base-button type="button" class="btn btn-sm btn-primary">
                    <label v-if="!document" for="documentInput" class="mb-0">
                      Select document
                    </label>
                    <label v-else for="documentInput" class="mb-0">Change</label>
                    <input
                      id="documentInput"
                      ref="documentInput"
                      type="file"
                      style="display: none;"
                      @input="onSelectFile"
                    />
                  </base-button>
                </div>
              </div>
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Upload Document
                </base-button>
                <div v-if="loading">
                      Upload in progress
                      <img src="/img/loading-unscreen.gif" style="height: 100px; width: 100px" />
                    </div>
                    </form>
                </template>
              </card>

            </modal>
    </div>
</template>
<script>
import { BasePagination } from "@/components";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import saveAs from "save-as";

export default {
  name: 'list-user-documents',
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: ['allDocuments'],

  data() {
    return {
      modals: {
        form: false,
      },
      loading: false,
      query: null,
      document: null,
      file: null,
      selectedRows: [],
      sort: "name",
      total: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      documents: [],
      documentPost: {
        type: 'documents',
        user: '',
        name: '',
      },
      loading: true,
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    allDocuments() {
      this.documents = this.allDocuments;
      this.documents.map((doc) => {
        doc.created_at = doc.created_at.substring(0, 10);
      })
    },
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    download(row) {
      let path = "/img" + row.url;

      fetch(path).then((res) => {
        let response = res.clone();

        response.blob().then((blobObj) => {
          saveAs(blobObj, row.name);
        });
      });
    },
    async uploadDocument() {
      try {
        if (this.document) {
          this.loading = true;
          let uploadedDocumentUrl = await this.$store.dispatch("users/uploadDocument", {
            user: this.$parent.user,
            document: this.file,
          });

          this.documentPost.user_id = this.$parent.user.id;
          this.documentPost.name = this.document;
          this.documentPost.url = uploadedDocumentUrl;

          await this.$store.dispatch("users/insertDocument", this.documentPost);
        }

	      this.modals.form = false;
        this.document = "";
        this.file = "";
        this.loading = false;
        this.$parent.get();

        this.$notify({
          type: "success",
          message: "Document uploaded successfully.",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    removeDocument() {
      this.document = null;
    },
    onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      let reader = new FileReader();
      reader.onload = (e) => {
        this.document = files[0].name;
        this.file = files[0];
      };
      reader.readAsDataURL(files[0]);
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.total = this.documents.length;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    async deleteDocument(id) {
      const confirmation = await swal.fire({
        title: `Delete this document?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("users/destroyDocument", id);
          await this.$parent.get();
          this.$notify({
            type: "success",
            message: "Document deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    addProject() {
      this.$router.push({ name: "Add Project" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
