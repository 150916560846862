<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">View Project</h3>
            </div>
            <div class="col-4 text-right">
              <base-dropdown
                menu-on-right
                class="nav-item"
                tag="li"
                title-tag="a"
                title-classes="nav-link pr-0"
              >
                <a href="#" class="btn btn-sm btn-primary" @click.prevent slot="title-container">
                  Actions
                </a>

                <template>
                  <a @click="editProject()" class="dropdown-item">
                    <span>Edit</span>
                  </a>
                  <a @click="copyPublicLink()" v-if="project.is_public == 'Make Private'" href="#" class="dropdown-item">
                    <span>Copy Link</span>
                  </a>
                  <a v-if=isAdmin @click="makePublic()" class="dropdown-item">
                    <span>{{ project.is_public }}</span>
                  </a>
                  <a v-if=isAdmin @click="addFolder()" class="dropdown-item">
                    <span>Create Folder</span>
                  </a>
                </template>
              </base-dropdown>
              <!-- <base-button
                v-if="project.is_public == 'Yes'"
                @click="copyPublicLink()"
                type="button"
                class="btn btn-sm btn-primary"
                >Copy Link</base-button
              >
              <base-button
                @click="makePublic()"
                v-if=isAdmin
                type="button"
                class="btn btn-sm btn-primary"
                >Public: {{ project.is_public }}</base-button
              >
             <base-button
                v-if=isAdmin
                @click="addFolder()"
                type="button"
                class="btn btn-sm btn-primary"
                >Create Folder</base-button
              > -->
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Back to list</base-button
              >
            </div>
          </div>
          
          <div class="card-body row">
              <div class="col-md-3" v-for="folder in this.project.folders" :key="folder.id">
                <div class="card">
                    <!-- Card image -->
                    <img @click="viewFolder(folder.id)" class="card-img-top" :src=folder.folder_image alt="Image placeholder">
                    <!-- Card body -->
                    <div class="card-body">
                    <h5 class="h2 card-title mb-0">{{ folder.name }}</h5>
                    <!-- <base-button type="button" @click="viewFolder(folder.id)" class="btn btn-link px-0">View More</base-button> -->
                    <base-button v-if=isAdmin type="button" @click="deleteFolder(folder.id)" class="btn btn-link text-danger px-0">Delete</base-button>
                    </div>
                </div>
              </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
  },

  data() {
    return {
      isAdmin: false,
      project: {
          name: null,
          is_public: null,
          share_link: null,
      }
    };
  },
  created() {
    this.get();
    this.isAdmin = this.$store.state.isAdmin;
  },

    methods: {
      async editProject()
      {
        this.$router.push({ name: "Edit Project", params: { id:  this.$route.params.id} });
        // this.$router.push({
        //   name: "Edit Project",
        //   params: {
        //     id: this.$route.params.id
        //   }
        // });
      },
    async deleteFolder(id) {
      const confirmation = await swal.fire({
        title: `Delete this folder? This will delete all of the associated data with it!`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("folders/destroy", id);
          await this.get();
          this.$notify({
            type: "success",
            message: "Folder deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
        async get() {
          try {
            const id = this.$route.params.id;
            await this.$store.dispatch("projects/get", id);
            this.project = this.$store.getters["projects/project"];
            this.project.user_id = this.project.user_id.split(",");

            this.mapPlaceholders();
          } catch (error) {
            this.$notify({
                type: "danger",
                message: `Oops, something went wrong!`,
            });

            this.setApiValidation(error.response.data.errors);
          }
        },

        async makePublic()
        {
          try {
            let result = '';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for ( let i = 0; i < 36; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            await this.$store.dispatch("projects/get", this.$route.params.id);
            this.project = this.$store.getters["projects/project"];
            this.project.share_link = result;
            this.project.user_id = this.project.user_id.split(",");
            
            if(this.project.share_link == null)
              delete this.project.share_link;

            this.project.is_public = !this.project.is_public;

            const type = this.project.is_public == 0 ? "private" : "public";

            await this.$store.dispatch("projects/update", this.project);
            this.mapPlaceholders();
           
            this.$notify({
              type: "success",
              message: "Project made "+ type +" successfully.",
            });
          } catch (error) {
            console.log(error);
            console.log(error.response);
          }
        },
        
        addFolder()
        {
            this.$router.push({ name: "Add Folder", params: { project_id:  this.$route.params.id} });
        },

        async copyPublicLink()
        {
          const publicLink = "https://portal.firstlookservice.com/public/" + this.project.share_link;

          // Copy to clipboard thingy: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
          const el = document.createElement('textarea');
          el.value = publicLink;
          el.setAttribute('readonly', '');
          el.style.position = 'absolute';
          el.style.left = '-9999px';
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);

          let title = "Project public link copied to your clipboard, or you can copy it here: " + publicLink;
          await swal.fire({
            title: `Link copied successfully!`,
            text: title,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-primary'
            }
          });
        },

        mapPlaceholders()
        {
          this.project.is_public = (this.project.is_public == 0) ? "Make Public" : "Make Private";

          this.project.folders.map((folder) => {
            if(folder.folder_image == 'placeholder')
              folder.folder_image = '/img/placeholder-folder.png';
            else
              folder.folder_image = "https://" + folder.folder_image;
          });

        },
    
    viewFolder(id)
    {
      this.$router.push({
        name: 'View Folder',
        params: { id: id }
      })
    },

    goBack() {
      this.$router.push({ name: "List Projects" });
    },
  },
};
</script>
