<template>
  <div class="container-fluid mt-5">
    <div class="row" v-if=isAdmin>
        <chat />
    </div>
    <div class="row" v-else>
      <chat-client />
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import chat from "@/views/FirstLook/Chat/Chat.vue";
import ChatClient from "@/views/FirstLook/Chat/ChatClient.vue";

export default {
  layout: "DashboardLayout",
  name: "Inbox",

  mixins: [formMixin],

  components: {
      chat,
      ChatClient
  },

  data() {
    return {
      isAdmin: true
    };
  },
  created() {
    this.isAdmin = this.$store.state.isAdmin;
  }
};
</script>

