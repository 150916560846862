<template>
    <div>
      <card
        class="no-border-card"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Project List</h3>
            </div>
            <div class="col-6 text-right">
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >

            <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Search..."
                clearable
              />
            </div>
          </div>
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="projects"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              label="Name"
              min-width="60px"
              prop="name"
              sortable="custom"
            />
            <el-table-column
              label="Folders"
              min-width="60px"
              prop="folder_count"
              sortable="custom"
            />
            <el-table-column
              label="Public"
              min-width="60px"
              prop="is_public"
              sortable="custom"
            />
            <el-table-column min-width="50px" align="center">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip content="View" placement="top">
                  <a
                    type="text"
                    @click="viewProject(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-user"></i>
                  </a>
                </el-tooltip>

                <el-tooltip content="Delete" placement="top">
                  <a
                    type="text"
                    @click="deleteProject(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ total ? from + 1 : 0 }} to {{ to }} of
              {{ total }} projects

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
</template>
<script>
import { BasePagination } from "@/components";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  name: 'list-user-projects',
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: ['allProjects'],

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "name",
      total: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      projects: [],
      loading: true,
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    allProjects() {
      this.projects = this.allProjects;
      this.projects.map((project) => {
        project.folder_count = project.folders.length;
      });

      this.total = this.projects.length;
    },
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    viewProject(row) {
      this.$router.push({
        name: "View Project",
        params: {
          id: row.id
        }
      });
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.projects.map((project) => {
            if(project.is_public == 0)
                project.is_public = "No";
            else
                project.is_public = "Yes";
        });

        this.total = this.projects.length;

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    async deleteProject(id) {
      if (this.$isDemo && ["1", "2", "3"].includes(id)) {
        this.$notify({
          type: "danger",
          message: "You are not allowed not change data of default projects.",
        });
        return;
      }
      const confirmation = await swal.fire({
        title: `Delete this project? This will delete all of the associated data with it!`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("projects/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Project deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    addProject() {
      this.$router.push({ name: "Add Project" });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
