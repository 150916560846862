<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Edit Project</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Back to list</base-button
              >
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="handleSubmit">
              <base-input
                label="Project Name"
                prepend-icon="fas fa-laptop"
                v-model="project.name"
              />
              <validation-error :errors="apiValidationErrors.name" />
                  <base-input label="Clients">
                      <el-select v-model="project.user_id"
                                 multiple
                                 filterable
                                 placeholder="Select Clients">
                        <el-option
                        v-for="user in available_users"
                        :key="user.id"
                        :value="user.id"
                        :label="user.name"
                    >
                    </el-option>
                      </el-select>
                    </base-input>
              <validation-error :errors="apiValidationErrors.users" />
              
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Edit Project
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option
  },

  data() {
    return {
      available_users: [],
      project: {
        type: "projects",
        name: null,
        user_id: null,
      }
    };
  },
  created() {
    this.get();
    this.getUsers();
  },

  methods: {
    getUsers() {
      try {
        this.$store.dispatch("users/list").then(() => {
            this.available_users = this.$store.getters["users/list"].filter((user) => {
              return user.name !== "Admin";
            });
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("projects/get", id);
        this.project = this.$store.getters["projects/project"];
        this.project.user_id = this.project.user_id.split(",");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async handleSubmit() {
      try {
        console.log(this.project);
        await this.$store.dispatch("projects/update", this.project);

        this.$notify({
          type: "success",
          message: "Project updated successfully.",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    goBack() {
      this.$router.push({ name: "List Projects" });
    },
  },
};
</script>
