<template>
<div class="container-fluid">
    <div class="col-md-12">
    <div class="card">
        <!-- Card header -->
        <div class="card-header">
            <!-- Title -->
            <h5 class="h3 mb-0">Contact FirstLook</h5>
        </div>
        <!-- Card body -->
        <div class="card-body" style="height: 550px; overflow-y: scroll;">
            <!-- List group -->
          <ChatMessageClient v-bind:all-messages=this.allMessages />
          <div id="zaAnchor"></div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-md-10">
              <base-input
                prepend-icon="fas fa-comment"
                placeholder="Write your message here"
                v-model="chatMessage.content"
              />
            </div>
            <div class="col-md-2">
              <base-button
                type="button"
                class="btn btn-sm btn-primary mt-2"
                native-type="button"
                @click="sendMessage"
                >Send Message</base-button>
            </div>
          </div>
        </div>
    </div>
  </div>
  </div>
</template>
<script>
  import ChatMessageClient from "@/views/FirstLook/Chat/ChatMessageClient.vue";
  import {mapGetters} from 'vuex';
  import Pusher from 'pusher-js';

  export default {
    name: 'ChatClient',
    components: {
      ChatMessageClient
    },
    props: {
      showSearch: {
        type: Boolean,
        default: true
      }
    },
    mounted() {
      this.$root.$on('updateMessages', (data) => {
        this.allMessages = data;

        if(this.$route.name == 'All Messages')
        {
          this.allMessages.map(async message => {
            if(message.seen == 0)
            {
              message.id = message.id.toString();
              message.seen = 1;
              message.type = "messages";

              await this.$store.dispatch('messages/update', message);
            }
          });
        }

        setTimeout(function() {
          let anchor = document.getElementById("zaAnchor");
          anchor.scrollIntoView();
        }, 500);
      });
    },
    computed: {
      ...mapGetters([
        'messages'
      ])
    },
    created() {
      this.getUser().then(async () => {
        await this.$store.dispatch('messages/GET_MESSAGES', this.user.id);

        this.allMessages = this.$store.getters['messages/messages'];

        Promise.all(this.allMessages.map(async (message) => {
          if(message.seen == 0)
          {
            message.seen = 1;

            await this.$store.dispatch('messages/update', message);
          }
        })).then(() => {
          this.$root.$emit('updateNotifications', this.user.id);
        }).then(() => {
          setTimeout(function() {
            let anchor = document.getElementById("zaAnchor");
            anchor.scrollIntoView();
          }, 500);
        });
      });
    },
    data() {
      return {
        user: {
          type: "profile",
          id: null,
          name: null,
          email: null
        },
        membersSearch: '',
        channel: {},
        messages: [],
        allMessages: [],
        chatMessage: {
          type: 'messages',
          from_user: "",
          to_user: "1",
          content: '',
          seen: 0
        },
      }
    },
    methods: {
      async getUser() {
        await this.$store.dispatch("profile/me");
        this.user = await this.$store.getters["profile/me"];
      },
      async sendMessage() {
        this.chatMessage.from_user = this.user.id;

        await this.$store.dispatch('messages/ADD_MESSAGE', this.chatMessage)
          .then(response => {
            this.chatMessage.content = "";

            setTimeout(function() {
              let anchor = document.getElementById("zaAnchor");
              anchor.scrollIntoView();
            }, 500);
          }).catch(err => {
            this.$notify({
              type: "danger",
              message: "Error sending a message.",
            });
            console.log(err);
          })       
      }
    }
  }
</script>