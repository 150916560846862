import service from "@/store/services/folders-service";

const state = {
  list: {},
  folder: {},
  meta: {},
  media: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, folder) => {
    state.folder = folder;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.folder_image = url;
  },
  SET_MEDIA: (state, media) => {
    state.media = media;
  }
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then(role => {
      commit("SET_RESOURCE", role);
    });
  },

  getPublicOnly({ commit, dispatch }, params) {
    return service.getPublicOnly(params).then(role => {
      commit("SET_RESOURCE", role);
    });
  },

  getPublic({ commit, dispatch }, params) {
    return service.getPublic(params).then(role => {
      commit("SET_RESOURCE", role);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then(folder => {
      commit("SET_RESOURCE", folder);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then(folder => {
      commit("SET_RESOURCE", folder);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },

  getMedia({ commit, dispatch }, params) {
    return service.getMedia(params).then(media => {
      commit("SET_MEDIA", media);
    });
  },

  upload({commit, dispatch}, {folder, image}) {
    return service.upload(folder, image, this.$axios)
      .then((url) => {
        commit('SET_URL', url);
        return url;
      });
  },

  mediaUpload({commit, dispatch}, {folder, image}) {
    return service.mediaUpload(folder, image, this.$axios);
  },

  mediaInsert({commit, dispatch}, params) {
    return service.mediaInsert(params, this.$axios);
  },

  mediaDestroy({commit, dispatch}, params) {
    return service.mediaDestroy(params);
  },

  contentChangeEmail({commit, dispatch}, params) {
    return service.contentChangeEmail(params, this.$axios);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  folder: state => state.folder,
  media: state => state.media,
  folder_image: state => state.folder_image,
  dropdown: state => {
    return state.list.map(folder => ({
      id: folder.id,
      name: folder.name
    }));
  }
};

const folders = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default folders;
